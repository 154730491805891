<!--
// nuxt-ui/components/pages/region-home/GridPartnersCompetition.vue
-->
<script setup lang="ts">
import type { SeasonPartner } from "~/src/season-cluster/domain/models/01.Season";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useRegions } from "~/nuxt-ui/Shared/composables/regions";

const { currentSeason } = useCompetitionStore();
const { isWorldCup } = useRegions();
const partners = ref<SeasonPartner[]>();

let featuredPartners: SeasonPartner[] = [];
let otherPartners: SeasonPartner[] = [];

if (currentSeason) {
  partners.value = currentSeason?.metaInformation?.season_partners;

  if (partners.value) {
    partners.value.map(partner => {
      if (partner.isFeatured) featuredPartners.push(partner);
      if (!partner.isFeatured) otherPartners.push(partner);
    });
  }
}
</script>

<template>
  <section v-if="partners && partners.length" id="partnersGrid" class="border-t-primary border-t-2">
    <div class="kql-w-container pb-20">
      <div :class="['container-grid-row py-12', { 'lg:px-20': isWorldCup }]">
        <div v-for="featuredPartner in featuredPartners" class="container-partner">
          <div class="image-container">
            <img
              :src="featuredPartner.imageUrl"
              :alt="featuredPartner.name"
              class="max-w-full max-h-full"
              loading="lazy"
              width="auto"
              height="76"
            />
          </div>
          <div class="partner-type-text">
            {{ featuredPartner.partnerType }}
          </div>
        </div>
      </div>
      <div :class="['container-grid-row gap-y-3 md:gap-y-8', { 'lg:px-20': isWorldCup }]">
        <div v-for="partner in otherPartners" class="container-partner">
          <div class="image-container">
            <img
              :src="partner.imageUrl"
              :alt="partner.name"
              class="max-w-full max-h-full"
              loading="lazy"
              width="auto"
              height="76"
            />
          </div>
          <div class="partner-type-text">
            {{ partner.partnerType }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.container-grid-row {
  @apply flex justify-center items-center gap-x-2 flex-wrap;
}

.container-partner {
  @apply flex flex-col justify-center items-center;
}

.image-container {
  @apply w-[155px] h-[65px] sm:w-[180px] sm:h-[76px] py-5 px-4 flex flex-col justify-center items-center;
}

.partner-type-text {
  @apply h-[26px] text-gray-400 text-xs font-medium flex items-center justify-center;
}
</style>
